import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { MdDelete } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import dayjs from 'dayjs';
import {
  timelineEntry,
  blue,
  red,
  white,
  timelineEntryRightSection,
  timelineEntryLeftSection,
  timelineEntryTitle,
  timelineDeleteIcon,
  timelineEntryDate,
  timelineEntryLink,
  sampleTimelineEntry,
  modalTimelineEntry,
  modalEntryDescription,
  modalExampleTitle,
} from './TimelineEntry.module.css';
import { EntryType, EntryTypeEnum } from '../../types/EntryType';
import Button from '../shared/Button';
import ModalContext from '../../contexts/ModalContext';
import useUser from '../../hooks/useUser';

const getLink = (type: EntryType, resourceId: string) => {
  let text;
  let link;
  if (type === EntryTypeEnum.journal) {
    text = 'View Entry';
    link = `/app/journal/entry/${resourceId}`;
  }

  if (type === EntryTypeEnum.piechart) {
    text = 'View Results';
    link = `/app/journal/activity/piechart/${resourceId}`;
  }

  if (type === EntryTypeEnum.wplh) {
    text = 'View Results';
    link = `/app/journal/activity/wplh/${resourceId}`;
  }
  if (type === EntryTypeEnum.scurve) {
    text = 'View Results';
    link = `/app/journal/activity/scurve/${resourceId}`;
  }
  return { link, text };
};

const TimelineEntry = ({
  resourceId,
  date,
  title,
  description,
  type,
  entryId,
  isSampleEntry,
  isModalEntry,
}: {
  resourceId: string;
  date: number;
  title: string;
  description: string;
  type: EntryType;
  entryId: string;
  isSampleEntry: boolean;
  isModalEntry: boolean;
}) => {
  const getStyleFromType = (entryType: EntryType) => {
    if (entryType === EntryTypeEnum.resume) {
      return white;
    }
    if (entryType === EntryTypeEnum.journal) {
      return blue;
    }
    // all others are activities
    return red;
  };

  const { link, text } = getLink(type, resourceId);
  const { user } = useUser();

  if (user == null) {
    return <></>;
  }

  const { emitter, events } = useContext(ModalContext);

  const userId = user?.uid;

  const handleDelete = () => {
    emitter.emit(events.DELETE_TIMELINE_ENTRY_MODAL, {
      id: userId,
      timelineEntryId: entryId,
    });
  };

  const { t } = useTranslation();
  return (
    <div
      className={`${timelineEntry} ${getStyleFromType(type)} ${
        isSampleEntry && sampleTimelineEntry
      } ${isModalEntry && modalTimelineEntry}`}
    >
      <div className={timelineEntryLeftSection}>
        {isSampleEntry && <div>Example: </div>}
        {isModalEntry && type === EntryTypeEnum.resume && (
          <div className={modalExampleTitle}>
            {t('timelineTooltipModal.resumeTimelineEntry.example')}
          </div>
        )}
        {isModalEntry && type === EntryTypeEnum.journal && (
          <div className={modalExampleTitle}>
            {t('timelineTooltipModal.journalTimelineEntry.example')}
          </div>
        )}
        {isModalEntry && type === EntryTypeEnum.wplh && (
          <div className={modalExampleTitle}>
            {t('timelineTooltipModal.journalActivityTimelineEntry.example')}
          </div>
        )}
        <div className={timelineEntryTitle}>{title}</div>
        <div>
          {type !== EntryTypeEnum.resume && (
            <div className={`${isModalEntry && modalEntryDescription}`}>
              {description}
            </div>
          )}
          {type === EntryTypeEnum.resume && (
            <div className={`italic ${isModalEntry && modalEntryDescription}`}>
              {description}
            </div>
          )}
          {link && (
            <Button
              className={timelineEntryLink}
              onClick={
                isSampleEntry
                  ? undefined
                  : () => {
                      navigate(link);
                    }
              }
            >
              {text}
            </Button>
          )}
        </div>
      </div>
      <div className={timelineEntryRightSection}>
        <div className={timelineEntryDate}>
          {`${dayjs(new Date(date)).format('DD MMM YYYY')}`}
        </div>
        <div className={timelineEntryDate}>
          {`${dayjs(new Date(date)).format('dddd')}`}
        </div>
        <div className={timelineDeleteIcon}>
          <IconButton onClick={isSampleEntry ? undefined : handleDelete}>
            <MdDelete />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default TimelineEntry;
