import { navigate } from 'gatsby';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import firebase from 'gatsby-plugin-firebase';
import { Grid, Container, Tooltip } from '@material-ui/core';
import { MdInfoOutline } from 'react-icons/md';
import getTimelineEntries from '../../api/timeline/getTimelineEntries';

import LoadingScreen from '../../components/router/LoadingScreen';
import ScrollToTop from '../../components/shared/ScrollToTop';
import Button from '../../components/shared/Button';
import TopNavbar from '../../components/shared/TopNavbar';

import Timeline from '../../components/timeline/Timeline';
import CreateResume from '../../components/timeline/CreateResume';
import ResumePreview from '../../components/timeline/ResumePreview';
import SelectionBar, {
  DropdownTypeEnum,
} from '../../components/timeline/SelectionBar';

import { User } from '../../types/User';
import { Entry } from '../../types/Timeline';
import { EntryType, EntryTypeEnum } from '../../types/EntryType';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

import {
  contentContainer,
  timelineContainer,
  timelineBox,
  column,
  leftItem,
  journalButton,
  blue,
  red,
  black,
  promptMsg,
  outline,
  tooltip,
} from './timeline.module.css';
import EmptyTimeline from '../../components/timeline/EmptyTimeline';
import TimelineTooltip from '../../components/timeline/TimelineTooltip';

const TimelinePage = ({ user }: { user: User }) => {
  const timelineRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [dropdownType, setDropdownType] = useState<DropdownTypeEnum>(
    DropdownTypeEnum.all,
  );
  const [resumes, setResumes] = useState<ResumeDatabaseObject[]>([]);
  const [entries, setEntries] = useState<Entry[]>([]);

  useEffect(() => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';

    if (user == null) {
      return;
    }

    getTimelineEntries(user.uid).then((ent) => {
      if (ent == null) {
        return;
      }
      setEntries(ent);
    });

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const resumesArr: ResumeDatabaseObject[] = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => resumesArr.push(data[key]));
          setResumes(resumesArr);
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setResumes(resumes.filter((x) => x.id === snapshot.val().id));
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
    };
  }, [user]);

  useEffect(() => {
    firebase
      .database()
      .ref(`timelines/${user.uid}`)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const newEntries: Entry[] = Object.values(snapshot.val());
          newEntries.sort((a, b) => {
            return b.date - a.date;
          });
          setEntries(newEntries);
        }
      });

    firebase
      .database()
      .ref(`timelines/${user.uid}`)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setEntries(entries.filter((x) => x.entryId === snapshot.val().id));
        }
      });

    return () => {
      firebase.database().ref(`timelines/${user.uid}`).off();
    };
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  function getDropdownType(type: EntryType) {
    if (type === EntryTypeEnum.journal) {
      return DropdownTypeEnum.journal;
    }
    if (type === EntryTypeEnum.resume) {
      return DropdownTypeEnum.resume;
    }
    return DropdownTypeEnum.activity;
  }

  const handleGoToJournalEntries = () => {
    navigate('/app/journal');
  };

  const handleGoToCreateJournalEntry = () => {
    navigate('/app/journal/entry');
  };

  const handleGoToJournalActivities = () => {
    navigate('/app/journal/activity');
  };

  return (
    <div className="h-screen w-screen">
      <TopNavbar />
      <Container className={contentContainer}>
        <Grid container className={contentContainer} spacing={10}>
          <Grid container item md={6} className={column}>
            <div>
              <div className="flex justify-start">
                <h1 className="pr-4">Timeline</h1>
                <Tooltip
                  title={<TimelineTooltip />}
                  placement="right"
                  arrow
                  classes={{ tooltip }}
                  className="pl-4"
                >
                  <div className="self-center">
                    <MdInfoOutline size={30} />
                  </div>
                </Tooltip>
              </div>
              <h2 className={promptMsg}>
                <span className="font-semibold">
                  Track your career progress through this timeline.
                </span>{' '}
                You will see your resumes, journal entries, and journal
                activities.
              </h2>
            </div>
            <Grid container xs={12} className={leftItem}>
              <Grid item sm={4}>
                <Button
                  onClick={handleGoToCreateJournalEntry}
                  className={`${journalButton} ${blue}`}
                >
                  {t('timeline.journalButtons.button1')}
                </Button>
              </Grid>
              <Grid item sm={4}>
                <Button
                  onClick={handleGoToJournalActivities}
                  className={`${journalButton} ${red}`}
                >
                  {t('timeline.journalButtons.button2')}
                </Button>
              </Grid>
              <Grid item sm={4}>
                <Button
                  onClick={handleGoToJournalEntries}
                  className={`${journalButton} ${black}`}
                >
                  {t('timeline.journalButtons.button3')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={leftItem}>
              <SelectionBar
                dropdownType={dropdownType}
                setDropdownType={setDropdownType}
              />
            </Grid>
            <Grid item xs={12} className={timelineContainer}>
              <div className={timelineBox} ref={timelineRef}>
                {entries.length !== 0 ? (
                  <Timeline
                    entries={entries.filter((entry) => {
                      return (
                        dropdownType === DropdownTypeEnum.all ||
                        getDropdownType(entry.type) === dropdownType
                      );
                    })}
                  />
                ) : (
                  <EmptyTimeline />
                )}
              </div>
              <ScrollToTop elRef={timelineRef} />
            </Grid>
          </Grid>
          <Grid item md={6} className={column}>
            <Grid container item>
              <div className="pb-1">
                <h1>My Resumes</h1>
              </div>
              <Grid container spacing={2} xs={12} className="pt-1">
                {resumes.map((x) => (
                  <Grid item xs={4}>
                    <ResumePreview key={x.id} resume={x} />
                  </Grid>
                ))}
                {resumes.length < 3 && (
                  <Grid item xs={4}>
                    <CreateResume />
                  </Grid>
                )}
                {resumes.length < 2 &&
                  Array(2 - resumes.length)
                    .fill(null)
                    .map((x) => (
                      <Grid item xs={4}>
                        <div className={outline} />
                      </Grid>
                    ))}
              </Grid>
            </Grid>
            <Grid container item />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TimelinePage;
