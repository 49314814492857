import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdSearch } from 'react-icons/io';
import {
  selectionBar,
  searchBar,
  filterControl,
  selectFilter,
  searchIcon,
} from './SelectionBar.module.css';

type SelectionBarProps = {
  dropdownType: DropdownTypeEnum;
  setDropdownType: React.Dispatch<React.SetStateAction<DropdownTypeEnum>>;
};

export enum DropdownTypeEnum {
  all = 'all',
  activity = 'activities',
  journal = 'entries',
  resume = 'resume',
}

export default function SelectionBar({
  dropdownType,
  setDropdownType,
}: SelectionBarProps) {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    // eslint-disable-next-line prefer-destructuring
    const value: DropdownTypeEnum = e.target.value;
    setDropdownType(value);
  };
  const isSearchBarVisible = false;

  return (
    <div className={selectionBar}>
      {isSearchBarVisible && (
        <TextField
          className={searchBar}
          id="outlined-basic"
          variant="outlined"
          fullWidth
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IoMdSearch size={18} className={searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      )}
      <FormControl variant="outlined" className={filterControl}>
        <Select
          className={selectFilter}
          labelId="simple-select"
          id="filter"
          value={dropdownType}
          onChange={handleChange}
        >
          <MenuItem value={DropdownTypeEnum.all}>
            {t('timeline.selectionBarDropdown.allLabel')}
          </MenuItem>
          <MenuItem value={DropdownTypeEnum.journal}>
            {t('timeline.selectionBarDropdown.entryLabel')}
          </MenuItem>
          <MenuItem value={DropdownTypeEnum.activity}>
            {t('timeline.selectionBarDropdown.activityLabel')}
          </MenuItem>
          <MenuItem value={DropdownTypeEnum.resume}>
            {t('timeline.selectionBarDropdown.resumeLabel')}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
