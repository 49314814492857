import firebase from 'gatsby-plugin-firebase';
import { Entry } from '../../types/Timeline';

const getTimelineEntries = async (userId: string): Promise<Entry[] | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`timelines/${userId}`)
      .orderByChild('date')
      .limitToLast(50)
      .once('value');
    const entries: Entry[] = Object.values(snapshot.val());
    entries.sort((a, b) => {
      return b.date - a.date;
    });
    return entries;
  } catch (error) {
    return null;
  }
};

export default getTimelineEntries;
