import React from 'react';

import TimelineEntry from './TimelineEntry';
import { Entry } from '../../types/Timeline';
import { timelineStructureParent } from './Timeline.module.css';

export default function Timeline({ entries }: { entries: Array<Entry> }) {
  return (
    <div className={timelineStructureParent}>
      {entries.map((entry, index) => {
        return (
          <TimelineEntry
            isEnd={index === entries.length - 1}
            {...entry}
            isSampleEntry={false}
            isModalEntry={false}
          />
        );
      })}
    </div>
  );
}
