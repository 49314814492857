import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import { BsArrow90DegRight } from 'react-icons/bs';
import TimelineEntry from './TimelineEntry';
import { Entry } from '../../types/Timeline';
import { timelineStructureParent } from './Timeline.module.css';
import { EntryType, EntryTypeEnum } from '../../types/EntryType';
import { rotateIcon } from './EmptyTimelineEntry.module.css';

export default function EmptyTimeline() {
  const { t } = useTranslation();
  const resumeTimelineEntry = t('sampleTimeline.resumeTimelineEntry');
  const journalEntryTimelineEntry = t(
    'sampleTimeline.journalEntryTimelineEntry',
  );
  const journalActivityTimelineEntry = t(
    'sampleTimeline.journalActivityTimelineEntry',
  );
  return (
    <div className="space-y-10">
      <div>
        <h1 className="text-center font-extrabold">
          No Timeline Entries Created Yet
        </h1>
        <h2 className="text-center">
          Create A Journal Entry, Try A Journal Activity, Or Create A Resume To
          Get Started
        </h2>
      </div>
      <div>
        <div className="flex justify-start space-x-3">
          <div>{resumeTimelineEntry}</div>
          <Icon className={rotateIcon}>
            <BsArrow90DegRight />
          </Icon>
        </div>
        <div>
          <TimelineEntry
            resourceId=""
            date={Date.now()}
            title="Data Analyst at Microsoft"
            description="Added New Experience to Resume 1"
            type={EntryTypeEnum.resume}
            entryId=""
            isSampleEntry
            isModalEntry={false}
          />
        </div>
        <div className="flex justify-start space-x-3">
          <div>{journalEntryTimelineEntry}</div>
          <Icon className={rotateIcon}>
            <BsArrow90DegRight className="rotate-90" />
          </Icon>
        </div>
        <div>
          <TimelineEntry
            resourceId=""
            date={Date.now()}
            title="My First Month at TikTok"
            description=""
            type={EntryTypeEnum.journal}
            entryId=""
            isSampleEntry
            isModalEntry={false}
          />
        </div>
        <div className="flex justify-start space-x-3">
          <div>{journalActivityTimelineEntry}</div>
          <Icon className={rotateIcon}>
            <BsArrow90DegRight className="rotate-90" />
          </Icon>
        </div>
        <div>
          <TimelineEntry
            resourceId=""
            date={Date.now()}
            title="My Work Play Love Health Dashboard"
            description=""
            type={
              EntryTypeEnum.wplh ||
              EntryTypeEnum.piechart ||
              EntryTypeEnum.scurve ||
              EntryTypeEnum.ikigai
            }
            entryId=""
            isSampleEntry
            isModalEntry={false}
          />
        </div>
        <h2>
          You can find more information about each timeline entry in the tool
          Tips icon at the top of the page.
        </h2>
      </div>
    </div>
  );
}
