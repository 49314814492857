import React from 'react';
import { useTranslation } from 'react-i18next';
import TimelineEntry from './TimelineEntry';
import { EntryTypeEnum } from '../../types/EntryType';
import { toolTip, toolTipHeaders } from './TimelineTooltip.module.css';

export default function EmptyTimeline() {
  const { t } = useTranslation();
  return (
    <div className={toolTip}>
      <div>
        <h2 className={`text-left font-bold text-2xl ${toolTipHeaders}`}>
          {t('timelineTooltipModal.toolTipHeading')}
        </h2>
        <h2 className={`text-left ${toolTipHeaders}`}>
          {t('timelineTooltipModal.toolTipDescription')}
        </h2>
      </div>
      <div>
        <div className="text-sm">
          <TimelineEntry
            resourceId=""
            versionId=""
            date={Date.now()}
            title={t('timelineTooltipModal.resumeTimelineEntry.title')}
            description={t(
              'timelineTooltipModal.resumeTimelineEntry.description',
            )}
            type={EntryTypeEnum.resume}
            entryId=""
            isSampleEntry={false}
            isModalEntry
          />
        </div>
        <h2 className="underline underline-offset-8 font-bold">
          {t('timelineTooltipModal.tips')}
        </h2>

        <p>
          <text>{t('timelineTooltipModal.resumeEntryTips.firstTipParta')}</text>
          <text className="font-bold">
            {t('timelineTooltipModal.resumeEntryTips.firstTipExample')}
          </text>
          <text>{t('timelineTooltipModal.resumeEntryTips.firstTipPartb')}</text>
        </p>
        <p>{t('timelineTooltipModal.resumeEntryTips.secondTip')}</p>
        <p>{t('timelineTooltipModal.resumeEntryTips.thirdTip')}</p>
        <p>{t('timelineTooltipModal.resumeEntryTips.fourthTip')}</p>
        <div>
          <TimelineEntry
            resourceId=""
            versionId=""
            date={Date.now()}
            title={t('timelineTooltipModal.journalTimelineEntry.title')}
            description=""
            type={EntryTypeEnum.journal}
            entryId=""
            isSampleEntry={false}
            isModalEntry
          />
        </div>
        <h2 className="underline underline-offset-8 font-bold">
          {t('timelineTooltipModal.tips')}
        </h2>
        <p>{t('timelineTooltipModal.journalEntryTips.firstTip')}</p>
        <p>{t('timelineTooltipModal.journalEntryTips.secondTip')}</p>
        <p>{t('timelineTooltipModal.journalEntryTips.thirdTip')}</p>
        <p>{t('timelineTooltipModal.journalEntryTips.fourthTip')}</p>
        <div>
          <TimelineEntry
            resourceId=""
            versionId=""
            date={Date.now()}
            title={t('timelineTooltipModal.journalActivityTimelineEntry.title')}
            description=""
            type={
              EntryTypeEnum.wplh ||
              EntryTypeEnum.piechart ||
              EntryTypeEnum.scurve ||
              EntryTypeEnum.ikigai
            }
            entryId=""
            isSampleEntry={false}
            isModalEntry
          />
        </div>
        <h2 className="underline underline-offset-8 font-bold">
          {t('timelineTooltipModal.tips')}
        </h2>
        <p>{t('timelineTooltipModal.journalActivitesEntryTips.firstTip')}</p>
        <p>{t('timelineTooltipModal.journalActivitesEntryTips.secondTip')}</p>
        <p>{t('timelineTooltipModal.journalActivitesEntryTips.thirdTip')}</p>
        <p>{t('timelineTooltipModal.journalActivitesEntryTips.fourthTip')}</p>
      </div>
    </div>
  );
}
