import React, { useState, useEffect, useCallback } from 'react';
import { FiArrowUp } from 'react-icons/fi';
import IconButton from '@material-ui/core/IconButton';
import styles from './ScrollToTop.module.css';

export default function ScrollToTop({ elRef }) {
  const [show, setShow] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollPos = elRef.current.scrollTop;
    if (scrollPos > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [elRef, setShow]);

  useEffect(() => {
    if (elRef == null || elRef.current == null) {
      return;
    }
    elRef.current.addEventListener(`scroll`, handleScroll);
    return () => {
      if (elRef == null || elRef.current == null) {
        return;
      }
      elRef.current.removeEventListener(`scroll`, handleScroll);
    };
  }, [elRef, handleScroll]);

  const handleClick = () => {
    elRef.current.scrollTo({ top: 0, behavior: `smooth` });
  };

  return (
    <div className={`${styles.button} ${!show && styles.none}`}>
      <IconButton onClick={handleClick} aria-label="to top" component="span">
        <FiArrowUp />
      </IconButton>
    </div>
  );
}
